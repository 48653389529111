import React from "react"
import { connect } from "react-redux"
import { actions, Control, Form as ReduxForm } from "react-redux-form"
import Papa from "papaparse"
import { Form, Button } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SuccessApi from "../components/success"
import { expectAuthenticationState } from "../components/authentication"
import Loading from "../components/loading"
import { ErrorList, validateEmail } from "../components/validation"

function AdminPage() {
  return (
    <Layout>
      <SEO title="Admin" />
      <h1>Administration</h1>

      <h2>Reports</h2>
      <Button
        className="mr-3 mb-3"
        href="/api/admin/reports/users"
        target="_blank"
        variant="secondary"
      >
        Download users reports
      </Button>
      <Button
        className="mr-3 mb-3"
        href="/api/admin/reports/downloads"
        target="_blank"
        variant="secondary"
      >
        Downloads reports
      </Button>

      <Button
        className="mr-3 mb-3"
        href="/api/admin/reports/licenses"
        target="_blank"
        variant="secondary"
      >
        Download license reports
      </Button>
      <Button
        className="mr-3 mb-3"
        href="/api/admin/reports/licenses-api"
        target="_blank"
        variant="secondary"
      >
        Download API license reports
      </Button>
      <Button
        className="mr-3 mb-3"
        href="/api/admin/reports/tokens"
        target="_blank"
        variant="secondary"
      >
        Download token reports
      </Button>

      <h2>Perpetual licenses</h2>
      <PerpetualLicenses />
    </Layout>
  )
}

export default expectAuthenticationState({
  connected: true,
  onboarded: true,
  admin: true,
})(AdminPage)

class _PerpetualLicenses extends React.Component {
  loadedPerpetualLicenses = ""
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      updateInProgress: false,
      touched: false,
      successful: false,
    }
  }

  async componentDidMount() {
    const response = await fetch("/api/admin/perpetual-licenses-settings", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    })
    if (response.ok) {
      const { perpetualLicenseSettings } = await response.json()
      this.setState({ loading: false })
      const emails = Object.keys(perpetualLicenseSettings)
      const lines = emails.map(email => [
        email,
        ...perpetualLicenseSettings[email],
      ])
      const perpetualLicenses = Papa.unparse(lines, { delimiter: "; " })
      this.loadedPerpetualLicenses = perpetualLicenses
      this.props.changeModel(
        "perpetualLicensesSettingsForm.data.perpetualLicenses",
        perpetualLicenses,
      )
    } else {
      throw new Error(`Invalid response : ${response.status} content : ${await response.text()}`)
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading />
    }
    const { valid } = this.props
    const { updateInProgress, touched, successful } = this.state
    return (
      <>
        {successful && (
          <SuccessApi>Perpetual licenses settings updated</SuccessApi>
        )}
        <ReduxForm
          model="perpetualLicensesSettingsForm.data"
          onSubmit={values => this.handleSubmit(values)}
          onChange={values => this.handleChange(values)}
        >
          <Form.Group>
            <Form.Label
              htmlFor="perpetualLicensesField"
              className="is-required"
            >
              Enter users
            </Form.Label>
            <Control.textarea
              className="form-control"
              model=".perpetualLicenses"
              id="perpetualLicensesField"
              rows={10}
              placeholder={
                "john.doe@gmail.com;machine-pattern1-*;machine-pattern2-*\nalice.doe@gmail.com;machine-pattern1-*;machine-pattern2-*"
              }
              validators={{
                required: val => val && val.length,
                parsable: val => parseList(val).ok === true
              }}
              data-test="perpetual-licenses"
            />
            <ErrorList
              model=".perpetualLicenses"
              messages={{
                parsable: val => {
                  const parsed = parseList(val)
                  return <span>{parsed.ok === false ? parsed.error : ""}</span>
                }
              }}
            />
            <Form.Text className="text-muted">
              <strong>Pattern:</strong>
              {
                ' "john.doe@gmail.com; machine-pattern1-*; machine-pattern2-*"'
              }{" "}
              one user per line.
            </Form.Text>
          </Form.Group>
          <SuccessList className="mt-3" />
          {!updateInProgress ? (
            <Button
              type="submit"
              data-test="submit"
              disabled={!valid || !touched}
            >
              Update perpetual licenses
            </Button>
          ) : (
            <Loading />
          )}
        </ReduxForm>
      </>
    )
  }

  handleChange(values) {
    this.setState({
      touched: this.loadedPerpetualLicenses !== values.perpetualLicenses
    })
  }

  async handleSubmit({ perpetualLicenses }) {
    const result = parseList(perpetualLicenses)
    if (!result.ok) {
      throw new Error("Licenses not parsable")
    }
    this.setState({
      updateInProgress: true
    })

    const response = await fetch("/api/admin/perpetual-licenses-settings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify({
        settings: result.perpetualLicenseSettings,
      }),
    })
    if (response.ok) {
      this.setState({ updateInProgress: false }, async () => {
        const result = await response.json()
        if (result.status === "ok") {
          this.setState({ successful: true })
          this.loadedPerpetualLicenses = perpetualLicenses
          this.setState({ touched: false })
        } else {
          this.setState({
            updateInProgress: false,
          })
          throw new Error("Cannot update perpetual licenses settings")
        }
      })
    } else {
      this.setState({
        updateInProgress: false
      })
      throw new Error("Cannot update perpetual licenses settings")
    }
  }

  componentWillUnmount() {
    this.props.resetModel("perpetualLicensesSettingsForm.data")
  }
}

const PerpetualLicenses = connect(
  ({
    perpetualLicensesSettingsForm: {
      forms: {
        $form: { valid },
      }
    }
  }) => ({
    valid,
  }),
  dispatch => ({
    changeModel: (model, value) => dispatch(actions.change(model, value)),
    resetModel: model => dispatch(actions.reset(model))
  }),
)(_PerpetualLicenses)

function _SuccessList({ perpetualLicenses }) {
  const parsed = parseList(perpetualLicenses)
  if (!parsed.ok) {
    return null
  }
  const { perpetualLicenseSettings } = parsed
  const emails = Object.keys(perpetualLicenseSettings)
  return (
    <SuccessApi>
      <p>
        The following users have access to perpetual licenses for the machine
        patterns:
      </p>
      <ul className="mt-2">
        {emails.map(email => (
          <li key={email}>
            {email}
            <ul>
              {perpetualLicenseSettings[email].map(pattern => (
                <li key={pattern}>{pattern}</li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </SuccessApi>
  )
}

const SuccessList = connect(
  ({
    perpetualLicensesSettingsForm: {
      data: { perpetualLicenses },
      forms: {
        $form: { touched }
      }
    }
  }) => ({
    perpetualLicenses,
    touched
  })
)(_SuccessList)

function parseList(perpetualLicenses) {
  const result = Papa.parse(perpetualLicenses.trim())

  if (result.errors.length > 0) {
    return {
      ok: false,
      error:
        'Invalid pattern, please follow the "email;machine_name_pattern1;machine_name_pattern2" pattern, one user per line.'
    }
  }
  const data = result.data
  const trimmedData = data.map(row =>
    row.map(p => p.trim()).filter(p => p.length > 0)
  )
  for (let row of trimmedData) {
    if (row.length < 2) {
      return {
        ok: false,
        error:
          'Invalid pattern, please follow the "email;machine_name_pattern1;machine_name_pattern2" pattern, one user per line.'
      }
    }
    if (!validateEmail(row[0])) {
      return {
        ok: false,
        error: `Invalid email address "${row[0]}"`
      }
    }
  }

  const perpetualLicenseSettings = {}
  for (const [email, ...patterns] of trimmedData) {
    perpetualLicenseSettings[email] = patterns
  }

  return {
    ok: true,
    perpetualLicenseSettings
  }
}
